import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	FormInput,
	PageLayout,
	route,
} from 'carrier-fe';
import { useEffect, useState } from 'react';

function EventCreate() {
	const urlSearchParams = new URLSearchParams(window.location.search);
	const trainingCourseId = urlSearchParams.get('training_course_id');
	const trainingVenueId = urlSearchParams.get('training_venue_id');

	const [data, setData] = useState<{
		name: string;
		capacity?: number;
		map_url?: string;
		enabled: boolean;
		address_line_1?: string;
		address_line_2?: string;
		address_line_3?: string;
		town_city?: string;
		county_state?: string;
		postcode_zip?: string;
		country?: string;
		training_course_id?: string;
		training_venue_id?: string;
	}>({
		name: '',
		enabled: true,
		training_course_id: trainingCourseId || '',
		training_venue_id: trainingVenueId || '',
	});

	const [postcodeVisible, setPostcodeVisible] = useState<boolean>(false);

	const handleFieldUpdate = (field: string, value: any) => {
		let cloned: any = structuredClone(data);

		cloned[field] = value;

		setData(cloned);
	};
	useEffect(() => {
		if (data.town_city || data.address_line_1) {
			setPostcodeVisible(true);
		} else {
			setPostcodeVisible(false);
		}
	}, [data.town_city, data.address_line_1]);


	const handleSubmit = () => {
		axios
			.post(route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.STORE), data)
			.then((res) => console.log(res));
	};

	return (
		<PageLayout
			host={window.location.host}
			title="Create Training Event Venue"
		>
			<div className="row justify-content-center">
				<div className="col-5">
					<div className="row">
						<div className="col">
							<h2
								className="my-3"
								style={{ color: '#464C5E' }}
							>
								Create
							</h2>
						</div>
					</div>
					<FormInput
						type="text"
						label="Name"
						name="name"
						value={data.name}
						onChange={(value: any) =>
							handleFieldUpdate('name', value)
						}
					/>
					<FormInput
						type="number"
						label="Capacity"
						name="capacity"
						value={data.capacity ? data.capacity.toString() : ''}
						onChange={(value: any) =>
							handleFieldUpdate('capacity', value)
						}
					/>
					<FormInput
						type="text"
						label="Map URL"
						name="map_url"
						value={data.map_url || ''}
						onChange={(value: any) =>
							handleFieldUpdate('map_url', value)
						}
					/>
					<FormInput
						type="checkbox"
						label="Enabled"
						name="enabled"
						onChange={(value: any) =>
							handleFieldUpdate('enabled', value)
						}
						value={data.enabled ? 'true' : 'false'}
					/>
					<FormInput
						type="text"
						label="Postcode"
						name="postcode_zip"
						value={data.postcode_zip || ''}
						onChange={(value: any) =>
							handleFieldUpdate('postcode_zip', value)
						}
					/>
					<FormInput
						type="text"
						label="Address Line 1"
						name="address_line_1"
						value={data.address_line_1 || ''}
						onChange={(value: any) =>
							handleFieldUpdate('address_line_1', value)
						}
					/>
					<FormInput
						type="text"
						label="Address Line 2"
						name="address_line_2"
						value={data.address_line_2 || ''}
						onChange={(value: any) =>
							handleFieldUpdate('address_line_2', value)
						}
					/>
					<FormInput
						type="text"
						label="Address Line 3"
						name="address_line_3"
						value={data.address_line_3 || ''}
						onChange={(value: any) =>
							handleFieldUpdate('address_line_3', value)
						}
					/>
					<FormInput
						type="text"
						label="Town/City"
						name="town_city"
						value={data.town_city || ''}
						onChange={(value: any) =>
							handleFieldUpdate('town_city', value)
						}
					/>
					<FormInput
						type="text"
						label="County/State"
						name="county_state"
						value={data.county_state || ''}
						onChange={(value: any) =>
							handleFieldUpdate('county_state', value)
						}
					/>

					{postcodeVisible && (
						<FormInput
							type="text"
							label="Postcode/Zip"
							name="postcode_zip"
							value={data.postcode_zip || ''}
							onChange={(value: any) =>
								handleFieldUpdate('postcode_zip', value)
							}
							required
						/>
					)}
					<FormInput
						type="text"
						label="Country"
						name="country"
						value={data.country || ''}
						onChange={(value: any) =>
							handleFieldUpdate('country', value)
						}
					/>
					<Button
						label="Create"
						className="d-inline-flex"
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</PageLayout>
	);
}

export default EventCreate;
