import axios from 'axios';
import {
	API_ENDPOINTS,
	Badge,
	Card,
	CardBody,
	PageLayout,
	route,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrainingCourse } from '../types';

function Course() {
	const { courseId } = useParams();
	const [data, setData] = useState<TrainingCourse>();

	const getCourse = () => {
		axios
			.get(
				route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.VIEW, {
					trainingCourse: String(courseId),
				})
			)
			.then((res) => setData(res.data.data));
	};

	useEffect(() => {
		getCourse();
	}, []);

	return (
		<PageLayout
			host={window.location.host}
			title="Course"
		>
			<Card className="flex-lg-row flex-lg-row-reverse overflow-hidden">
				{data?.image && (
					<div>
						<img
							src={data.image}
							className="object-fit-cover w-100 h-100"
							style={{ objectPosition: 'left' }}
						/>
					</div>
				)}
				<CardBody className="p-5">
					<div className="d-flex justify-content-between align-items-center mb-2">
						<h6
							className="mb-0 text-uppercase fw-medium"
							style={{ color: '#868692' }}
						>
							Created: {data?.created_at}
						</h6>
						<Badge label={data?.training_category.name || ''} />
					</div>

					<h1 style={{ marginBottom: '32px' }}>{data?.name}</h1>

					<h6>Description</h6>
					<p style={{ color: '#667085' }}>
						{data?.description ||
							'Lorem ipsum dolor sit amet consectetur. Ultricies morbi ipsum vehicula purus. Faucibus lectus ante nunc volutpat nam nunc amet est diam. Donec eros ullamcorper nec varius suspendisse. Ultricies sit ut tincidunt cum commodo nunc.'}
					</p>

					<h6>Course expiry</h6>
					<p style={{ color: '#667085' }}>
						{data?.course_expiry_years || '-'}
					</p>

					<h6>Requires in-person event</h6>
					<p style={{ color: '#667085' }}>
						{data?.requires_in_person_event ? 'Yes' : 'No'}
					</p>

					{data?.deleted_at && (
						<>
							<h6>Archived</h6>
							<p style={{ color: '#667085' }}>
								{data?.deleted_at}
							</p>
						</>
					)}
				</CardBody>
			</Card>
		</PageLayout>
	);
}

export default Course;
