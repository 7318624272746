import axios from 'axios';
import {
	API_ENDPOINTS,
	Badge,
	Card,
	CardBody,
	PageLayout,
	route,
} from "carrier-fe";

import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from '../types';
import { format } from 'date-fns';

type Field<T> = {
	label: string;
	value: (data: T) => React.ReactNode;
};

const fields: Field<Row>[] = [
	{ label: "Name", value: (data) => data?.training_venue?.name },
	{ label: "Description", value: (data) => data?.description},
	{ label: "Capacity", value: (data) => data?.training_venue?.capacity },
	{ label: "Map URL", value: (data) => data?.training_venue?.map_url ? (
		<a href={data.training_venue.map_url} target="_blank" rel="noopener noreferrer">
			Open Map
		</a>
	) : '-' },
	{ label: "Enabled", value: (data) => data?.training_venue?.enabled ? 'Y' : 'N' },
	{ label: "Address Line 1", value: (data) => data?.training_venue?.address?.address_line_1 },
	{ label: "Address Line 2", value: (data) => data?.training_venue?.address?.address_line_2 },
	{ label: "Address Line 3", value: (data) => data?.training_venue?.address?.address_line_3 },
	{ label: "Town / City", value: (data) => data?.training_venue?.address?.town_city },
	{ label: "County / State", value: (data) => data?.training_venue?.address?.state_county },
	{ label: "Postcode / ZIP", value: (data) => data?.training_venue?.address?.postcode_zipcode },
	{ label: "Country", value: (data) => data?.training_venue?.address?.country },
	{ label: "Created Date", value: (data) => data?.created_at ? format(new Date(data.created_at), 'dd/MM/yyyy') : '-' },
	{ label: "Last Updated Date", value: (data) => data?.updated_at ? format(new Date(data.updated_at), 'dd/MM/yyyy') : '-' },
];

function Event() {
	const { eventId } = useParams();
	const [data, setData] = useState<Row>({} as Row);

	const getEvent = useCallback(() => {
		axios
			.get<{data: Row}>(
				route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.VIEW, {
					trainingEvent: String(eventId),
				})
			)
			.then((res) => setData(res.data.data));
	}, [eventId]);

	useEffect(() => {
		getEvent();
	}, []);

	return (
		<PageLayout
			host={window.location.host}
			title="Training Venue Details"
		>
			<button onClick={() => window.history.back()} className="btn btn-secondary mb-3 " >
				Back to Events
			</button>
			<Card className="flex-lg-row flex-lg-row-reverse overflow-hidden">
				<div className='w-full md:w-1/2 lg:w-1/2'>
						<img
							src={data?.training_course?.image || ''}
							className="object-fit-cover w-100 h-100"
							style={{ objectPosition: 'left' }}
							alt="Event"
						/>
				</div>
				<CardBody className="p-5">
					<div className="d-flex justify-content-between align-items-center mb-2">
						<h6
							className="mb-0 text-uppercase fw-medium"
							style={{ color: '#868692' }}
						>
							Created: {data?.created_at}
						</h6>
						<Badge label={data?.name || ''} />
					</div>

					<h1 style={{ marginBottom: '32px' }}>{data?.name}</h1>

					{fields.map((field) => (
						<p style={{ color: '#667085' }} key={field?.label}>
							<strong>{field?.label}:</strong> {field?.value(data as Row) || '-'}
						</p>
					))}

					{data?.deleted_at && (
						<>
							<h6>Archived</h6>
							<p style={{ color: '#667085' }}>
								{format(
									new Date(data.deleted_at),
									'dd/MM/yyyy'
								)}
							</p>
						</>
					)}
				</CardBody>
			</Card>
		</PageLayout>
	);
}

export default Event;
