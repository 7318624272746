import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	FormInput,
	PageLayout,
	route,
	ValueType,
} from 'carrier-fe';
import { useEffect, useState } from 'react';

function CourseCreate() {
	const [data, setData] = useState<{
		name: string;
		training_category_id: string;
		description?: string;
		image?: ValueType;
		course_expiry_years?: string;
		requires_in_person_event: boolean;
	}>({
		name: '',
		training_category_id: '',
		requires_in_person_event: false,
	});

	const handleFieldUpdate = (field: string, value: any) => {
		let cloned: any = structuredClone(data);

		cloned[field] = value;

		setData(cloned);
	};

	const [categories, setCategories] = useState();

	const getTrainingCategories = () => {
		axios
			.get(route(API_ENDPOINTS.GENERIC.SELECT.TRAINING.CATEGORIES))
			.then((res) => setCategories(res.data.data));
	};

	const handleSubmit = () => {
		axios
			.post(route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.STORE), data)
			.then((res) => console.log(res));
	};

	useEffect(() => {
		getTrainingCategories();
	}, []);

	return (
		<PageLayout
			host={window.location.host}
			title="Course"
		>
			<div className="row justify-content-center">
				<div className="col-5">
					<div className="row">
						<div className="col">
							<h2
								className="my-3"
								style={{ color: '#464C5E' }}
							>
								Create
							</h2>
						</div>
					</div>
					<FormInput
						type="text"
						label="Name"
						name="name"
						value={data.name}
						onChange={(value: any) =>
							handleFieldUpdate('name', value)
						}
					/>
					<FormInput
						type="select"
						label="Category"
						name="training_category_id"
						value={data.training_category_id}
						onChange={(value: any) =>
							handleFieldUpdate('training_category_id', value)
						}
						placeholder="Please select"
						options={categories}
					/>
					<FormInput
						type="textarea"
						label="Description"
						name="description"
						placeholder="Description"
						value={data.description || ''}
						onChange={(value: any) =>
							handleFieldUpdate('description', value)
						}
					/>
					<FormInput
						type="file"
						label="image"
						name="image"
						value={data.image || ''}
						onChange={(value: any) =>
							handleFieldUpdate('image', value)
						}
					/>
					<FormInput
						type="checkbox"
						label="Course expiry (years)"
						name="course_expiry_years"
						value={data.course_expiry_years || ''}
						onChange={(value: any) =>
							handleFieldUpdate('course_expiry_years', value)
						}
					/>

					{/* <FormInput
						type="checkbox"
						label="Requires in-person event"
						name="requires_in_person_event"
						value="true"
						checked={data.requires_in_person_event === 'true'}
						onChange={(value: any) =>
							handleFieldUpdate('requires_in_person_event', value)
						}
					/>
					<FormInput
						type="checkbox"
						label="Requires in-person event"
						name="requires_in_person_event"
						value="false"
						checked={data.requires_in_person_event === 'false'}
						onChange={(value: any) =>
							handleFieldUpdate('requires_in_person_event', value)
						}
					/> */}
					<Button
						label="Save Changes"
						className="d-inline-flex"
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</PageLayout>
	);
}

export default CourseCreate;
