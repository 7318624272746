import { API_ENDPOINTS, DataTable, Icon, Icons, PageLayout, route } from 'carrier-fe';
import { Row } from './types';

const items = [
	{
		label: 'Create',
		disabled: false,
		attributes: { 'data-test': 'item1' },
		icon: <Icon icon={Icons.MENU} /> 
	}
];

function formatDate(dateString: string | null): string {
	if (!dateString) return '';
	const date = new Date(dateString);
	return date.toLocaleString('en-GB', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});
}

function Events() {
	const colMapping = (data: Row) => {
		return {
			Name: data.name || '-',
			'Date & Time': data.created_at || '-',
			Capacity: data.training_venue.capacity || '-',
			Created: formatDate(data.created_at) || '-',
			'Archived at': formatDate(data.deleted_at) || '-',
			Actions: (
				<select onChange={(e) => {
					const action = e.target.value;
					const trainingCourseId = data.training_course_id;
					const trainingVenueId = data.training_venue_id;
					window.location.href = `/training/event/${action}?training_course_id=${trainingCourseId}&training_venue_id=${trainingVenueId}`;
				}}>
					<option value="" disabled selected>Select an action</option>
					<option value="create">Create</option>
					<option value="view">View</option>
					<option value="edit">Edit</option>
					<option value="archive">Archive</option>
				</select>
			),
		};
	};

	return (
		<PageLayout
			host={window.location.host}
			title="Training Event Venues"
		>
			<DataTable<Row>
				routeEndpoint={route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.INDEX)}
				{...{
					colMapping,
					items,
					selectable: true,
					incrementPaginationButtons: true,
					sortableFields: {
						id: 'id',
						name: 'name',
						created_at: 'created_at',
					},
					
				}}
				

			/>
		</PageLayout>
	);
}

export default Events;
