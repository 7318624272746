import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Root from '../pages/root';
import Dashboard from '../pages/dashboard';
import {AuthenticatedLayout, Permissions, Platforms, ProtectedPage} from 'carrier-fe';
import Courses from '../pages/courses';
import Events from '../pages/events';
import Course from '../pages/courses/view';
import Event from '../pages/events/view';
import CourseCreate from '../pages/courses/create';
import EventCreate from '../pages/events/create';
import Test from "../pages/test";

function Router() {
	return (
		<BrowserRouter>
			<Routes>
				{/* TODO - REMOVE TEST PAGE */}
				<Route
					index
					path="/test"
					element={<Test />}
				/>

				<Route
					index
					path="/"
					element={<Root />}
				/>

				{/*
				 * TODO - FOLLOWING ROUTES NEED TO BE AUTH PROTECTED
				 *      - IS A TOKEN SET IN THE COOKIE?
				 *      - IS THAT TOKEN VALID (CHECK VIA THE SERVER?
				 *      - IF NOT -> LOGOUT AND REDIRECT TO LOGIN
				 */}
				<Route
					path="/"
					element={<AuthenticatedLayout platform={Platforms.ADMIN} />}
				>
					<Route
						index
						path="/dashboard"
						element={
							<ProtectedPage
								requiredPermissions={Permissions.ADMIN_PLATFORM_VIEW}
							>
								<Dashboard />
							</ProtectedPage>
						}
					/>
					<Route
						index
						path="/training/course"
						element={<Courses />}
					/>
					<Route
						path="/training/course/:courseId"
						element={<Course />}
					/>
					<Route
						path="/training/course/create"
						element={<CourseCreate />}
					/>
					<Route
						index
						path="/training/event"
						element={<Events />}
					/>
					<Route
						path="/training/event/:eventId"
						element={<Event />}
					/>
					<Route
						path="/training/event/create"
						element={<EventCreate />}
					/>
				</Route>

				{/* TODO - MAKE A PRETTY PAGE IN THE COMPONENT LIBRARY */}
				<Route
					path="*"
					element={<p>404</p>}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
